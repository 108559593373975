import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SimpleHeader from "../components/simpleHeader"

import { MailIcon, PhoneIcon } from "@heroicons/react/outline"
import { graphql } from "gatsby"
import ContactForm from "../components/contactForm"

const ContactPage = ({
  data: {
    contentfulContact: { title, introduction, email, phone, address },
  },
}) => (
  <Layout>
    <Seo title="Contact us" />

    <SimpleHeader
      smallTitle="Contact us"
      title={title}
      description={introduction}
    />

    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Get in touch
            </h2>

            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Company Address</dt>
                <dd>
                  {address.map(i => (
                    <p key={i}>{i}</p>
                  ))}
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <a href={`tel:${phone}`} className="ml-3">
                    {phone}
                  </a>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <a href={`mailto:${email}`} className="ml-3">
                    {email}
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    contentfulContact {
      title
      introduction
      email
      address
      phone
    }
  }
`

export default ContactPage
